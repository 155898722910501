import AutoComplete from 'js-autocomplete';

new AutoComplete({
    selector: '#search',
    cache: true,
    minChars: 1,
    delay: 20,
    source(term, response) {
        const request = new XMLHttpRequest();
        const params = { query: term };
        const encodedParams = Object.keys(params).map((param) => `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`);
        let autocompleteUrl = document.getElementById('search').getAttribute('data-autocomplete');
        autocompleteUrl = (autocompleteUrl.indexOf('?') === -1) ? `${autocompleteUrl}?` : `${autocompleteUrl}&`;
        request.open('GET', autocompleteUrl + encodedParams.join('&'), true);
        request.onload = function () {
            if (request.status >= 200 && request.status < 400) {
                // Success!
                response(JSON.parse(request.responseText));
            } else {
                // We reached our target server, but it returned an error
            }
        };
        request.onerror = function () {
            // There was a connection error of some sort
        };
        request.send();
    },
    renderItem: function (item, search) {
        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
        return '<div class="autocomplete-suggestion" data-ats-main-search-help="autocomplete-suggest" data-val="' + item + '">' + item.replace(re, "<b>$1</b>") + '</div>';
    },
    onSelect(e, term, item) {
        document.querySelector('#search').form.submit();
    },
});

new AutoComplete({
    selector: '#nav-search',
    menuClass: 'nav-menu',
    cache: true,
    minChars: 1,
    delay: 20,
    source(term, response) {
        const request = new XMLHttpRequest();
        const params = { query: term };
        const encodedParams = Object.keys(params).map((param) => `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`);
        let autocompleteUrl = document.getElementById('nav-search').getAttribute('data-autocomplete');
        autocompleteUrl = (autocompleteUrl.indexOf('?') === -1) ? `${autocompleteUrl}?` : `${autocompleteUrl}&`;
        request.open('GET', autocompleteUrl + encodedParams.join('&'), true);
        request.onload = function () {
            if (request.status >= 200 && request.status < 400) {
                // Success!
                response(JSON.parse(request.responseText));
            } else {
                // We reached our target server, but it returned an error
            }
        };
        request.onerror = function () {
            // There was a connection error of some sort
        };
        request.send();
    },
    onSelect(e, term, item) {
        document.querySelector('#nav-search').form.submit();
    },
});
